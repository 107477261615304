import { BaseButton } from '@/components/BaseButton/styles';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  padding: 2vh;
  margin-bottom: 4vh;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0;
  animation: show-up 500ms 1500ms ease forwards;

  @media (min-width: 500px) {
    display: none;
  }
`;

export const Button = styled(BaseButton)`
  width: 100%;

  background-color: #1a1a1a;
  border: 0.0625em solid #f55d0b;
  padding: 0.6em 0.4em;

  font-weight: 300;
  color: #f55d0b;
  font-size: 2.8vh;
`;

export const Img = styled.img`
  height: 1.4em;
  width: 1.4em;

  margin-right: 0.7em;
`;
