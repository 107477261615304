import styled from 'styled-components';
import LogoPng from '@assets/LogoBlack.svg';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  background-image: url(${LogoPng});
  fill: #1a1a1a;
  background-size: 34px 34px;

  box-shadow: inset -1px -1px 2px rgba(255, 255, 255, 0.01),
    inset 1px 1px 2px rgba(0, 0, 0, 0.6);

  display: flex;
  flex-direction: column;

  overflow: hidden;

  @media (min-width: 500px) {
    background: transparent;
    box-shadow: none;
  }
`;

// Content

export const Content = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;

  overflow: hidden;

  @media (min-width: 500px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;
