import React from 'react';

import { LinkCardProps, Contact } from './interfaces';
import {
  Container,
  Content,
  ItemContainer,
  LinkList,
  Img,
  Title,
  TextContainer,
} from './styles';

const renderItem =
  (onOpenLink: (url?: string) => void) =>
  ({ imgUrl, title, url, id }: Contact, index: number) =>
    (
      <Content key={id} delay={index * 250 + 1250}>
        <ItemContainer onClick={() => onOpenLink(url)}>
          <Img src={imgUrl} />
          <TextContainer>
            <Title>{title}</Title>
          </TextContainer>
        </ItemContainer>
      </Content>
    );

const LinkCard: React.FC<LinkCardProps> = ({ items }) => {
  const onOpenLink = (url?: string) => {
    if (!url) return;
    window.open(url, '_blank');
  };

  return (
    <Container>
      <LinkList>{items.map(renderItem(onOpenLink))}</LinkList>
    </Container>
  );
};

export default LinkCard;
