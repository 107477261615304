import styled from 'styled-components';

interface CardProps {
  delay?: number;
}

export const Container = styled.div`
  flex: 1;

  display: flex;
  overflow: auto;

  @media (min-width: 500px) {
    max-width: 50%;
    height: 60vh;
    flex: none;
  }
`;

export const LinkList = styled.div`
  flex: 1;
  padding: 2vh;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  gap: 3.2vh;

  & > * {
    width: -webkit-calc(50% - 1.6vh);
  }

  @media (min-width: 500px) {
    flex: none;
    gap: 2.4vh;

    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;

    & > * {
      width: auto;
    }
  }
`;

export const Content = styled.div<CardProps>`
  display: flex;

  opacity: 0;
  animation: show-up 500ms ${({ delay }) => delay}ms ease forwards;
`;

export const ItemContainer = styled.button`
  flex: 1;

  border: 0;
  outline: none;
  cursor: pointer;

  background-color: #1a1a1a;
  box-shadow: -8px -8px 25px rgba(255, 255, 255, 0.05),
    8px 8px 20px rgba(0, 0, 0, 0.6),
    inset -3px -3px 40px rgba(255, 255, 255, 0.05),
    inset 3px 3px 40px rgba(0, 0, 0, 0.4);
  border-radius: 0.5em;
  padding: 1.3em 0em;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > * + * {
    margin-top: 1em;
  }

  transition: transform 300ms ease-in-out;
  :hover,
  :focus {
    transform: scale(1);
  }

  overflow: hidden;

  @media (hover) {
    transform: scale(0.9);
  }
`;

export const Img = styled.img`
  height: 4vh;
  width: 4vh;

  display: block;
`;

export const TextContainer = styled.div`
  width: 100%;
  padding: 0em 1em;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: 500px) {
    padding: 0.4em 2em;
  }
`;

export const Title = styled.h2`
  display: block;

  font-weight: 300;
  text-align: center;
  font-size: 2vh;
  color: #e9ecef;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
