import styled from 'styled-components';

export const BaseButton = styled.button`
  outline: none;
  cursor: pointer;

  background-color: transparent;
  border-radius: 0.2em;
  padding: 0.4em;

  font-family: 'Barlow', sans-serif;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.5em;
  box-shadow: -8px -8px 25px rgba(255, 255, 255, 0.05),
    8px 8px 20px rgba(0, 0, 0, 0.6),
    inset -3px -3px 40px rgba(255, 255, 255, 0.05),
    inset 3px 3px 40px rgba(0, 0, 0, 0.4);

  transition: transform 300ms ease-in-out;
  :hover,
  :focus {
    transform: scale(1);
  }

  :active {
    transform: scale(0.9);
  }

  @media (hover) {
    transform: scale(0.9);
  }
`;
