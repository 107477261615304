import React, { useState, useEffect } from 'react';
import logo from '../../assets/logotype.png';
import iphone from '../../assets/iphoneDetails.png';
import android from '../../assets/androidDetails.png';
import androidNFC from '../../assets/androidNFC.png';
import iphoneNFC from '../../assets/iphoneNFC.png';
import card from '../../assets/card.png';

import {
  AppContainer,
  InfoButton,
  HeaderTitles,
  NavMenu,
  NavItem,
  Header,
  Logo,
  Indicator,
  MainContainer,
  Title,
  TitleText,
  BackgroundImage,
  InstructionsContainer,
  Instruction,
  InstructionNumber,
  InstructionText,
  IphoneAdvice,
  NFCSymbol,
  BackgroundFade,
  Modal,
  ModalText,
  ModalImage,
  CloseButtom,
} from './style';

const AboutUs: React.FC = () => {
  const [mode, setMode] = useState('iphone');
  const [modal, setModal] = useState(false);

  const changeMode = (newMode: string) => {
    setMode(newMode);
  };

  const iphoneInstructions = [
    <InstructionText>
      Abra a Central de Controle (conforme imagem abaixo)
    </InstructionText>,
    <InstructionText>
      Selecione o ícone de NFC
      <NFCSymbol src={iphoneNFC} />
    </InstructionText>,
    <InstructionText>
      Aproxime seu Dynamic Card a parte superior frontal do iPhone
    </InstructionText>,
  ];

  const androidInstructions = [
    <InstructionText>
      Abra a Central de notificações (conforme imagem abaixo)
    </InstructionText>,
    <InstructionText>
      Deslize novamente para baixo acessando todas os ícones de ferramentas
    </InstructionText>,
    <InstructionText>
      Procure pelo ícone de NFC <NFCSymbol src={androidNFC} /> e certifique de
      que ele está ativado
    </InstructionText>,
    <InstructionText>
      Aproxime o seu Dynamic Card a parte de trás do seu smartphone
    </InstructionText>,
  ];

  const [instructions, setInstructions] = useState(iphoneInstructions);

  useEffect(() => {
    if (mode === 'iphone') {
      setInstructions(iphoneInstructions);
    } else if (mode === 'android') {
      setInstructions(androidInstructions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  const toggleModal = () => {
    setModal(!modal);
  };

  return (
    <AppContainer>
      {modal && (
        <BackgroundFade>
          <Modal>
            <CloseButtom onClick={() => toggleModal()}>X</CloseButtom>
            <ModalText>
              Caso o seu smartphone não possua NFC, basta abrir a câmera e
              apontar para o QR Code no verso do seu Dynamic Card
            </ModalText>
            <ModalImage src={card} />
          </Modal>
        </BackgroundFade>
      )}
      <Header>
        <HeaderTitles>
          <Logo src={logo} />
          <InfoButton onClick={() => toggleModal()}>Não possui NFC?</InfoButton>
        </HeaderTitles>
        <NavMenu>
          <NavItem onClick={() => changeMode('iphone')}>Iphones</NavItem>
          <NavItem onClick={() => changeMode('android')}>Android</NavItem>
        </NavMenu>
        <Indicator mode={mode} />
      </Header>
      <MainContainer>
        <Title>
          <TitleText style={{ color: '#E9ECEF' }}>Como usar o seu</TitleText>
          <TitleText style={{ color: '#F55D0B' }}>Dynamic Card</TitleText>
        </Title>
        <InstructionsContainer>
          {instructions.map((instruction, index) => (
            <Instruction>
              <InstructionNumber>{index + 1}.</InstructionNumber>
              {instruction}
            </Instruction>
          ))}
          {mode === 'iphone' && (
            <IphoneAdvice>
              A partir do iPhone XR não há necessidade de ativação, basta
              aproximar o seu Dynamic Card
            </IphoneAdvice>
          )}
        </InstructionsContainer>
      </MainContainer>
      <BackgroundImage src={iphone} mode={mode} variant="iphone" />
      <BackgroundImage src={android} mode={mode} variant="android" />
    </AppContainer>
  );
};

export default AboutUs;
