import { IApiHelper } from '@/modules/shared/data/protocols/http/IApiHelper';
import { BaseGetApi } from '@/modules/shared/domain/interfaces';
import IApiServices from '@/modules/shared/domain/usecases/IApiServices';
import { HttpResponse } from '../protocols/http';

class ApiEdit<T> implements IApiServices<T> {
  private api: IApiHelper;

  private url: string;

  constructor(apiHelper: IApiHelper, url: string) {
    this.api = apiHelper;
    this.url = url;
  }

  public async get(params: BaseGetApi): Promise<HttpResponse<T>> {
    const { key } = params;
    const response = await this.api.get({ url: `${this.url}/${key}` });
    return response;
  }
}

export default ApiEdit;
