import React from 'react';

import Profile from '@components/ProfileCard';
import LinkCardList from '@components/LinkCard';

import { formatFields } from './utils';
import { Footer, Header } from './components';
import { Container, Content } from './styles';
import { CardMainProps } from './interfaces';

const MainCard: React.FC<CardMainProps> = ({ card }) => {
  return (
    <Container>
      <Header />
      <Content>
        <Profile card={card} />
        <LinkCardList items={formatFields(card)} />
      </Content>
      <Footer card={card} />
    </Container>
  );
};

export default MainCard;
