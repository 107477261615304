import styled from 'styled-components';
import { BaseButton } from '@/components/BaseButton/styles';

export const Container = styled.header`
  padding: 2vh;

  display: flex;
  align-items: center;
  justify-content: space-between;

  animation: show-up 500ms 0ms ease forwards;
`;

export const RightContainer = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 0.4em;
  }
`;

export const Logo = styled.img`
  width: 3vh;
  height: 3vh;

  filter: invert(1);

  @media (min-width: 500px) {
    width: 4vh;
    height: 4vh;
  }
`;

export const HeaderTitle = styled.h1`
  color: #e9ecef;
  font-size: 1.6vh;
  text-transform: uppercase;

  @media (min-width: 500px) {
    font-size: 2vh;
  }
`;

export const HeaderButton = styled(BaseButton)`
  padding: 0.6em 1.8em;
  border: 0;
  box-shadow: -8px -8px 25px rgba(255, 255, 255, 0.05),
    8px 8px 20px rgba(0, 0, 0, 0.6),
    inset -3px -3px 40px rgba(255, 255, 255, 0.05),
    inset 3px 3px 40px rgba(0, 0, 0, 0.4);
  background-color: #1a1a1a;

  font-weight: 500;
  color: #e9ecef;
  font-size: 1.7vh;

  @media (min-width: 500px) {
    font-size: 2vh;
  }
`;
