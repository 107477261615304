import React from 'react';

import formatPhone from '@/utils/formatPhoneNumber';
import Phone from '@assets/phone.svg';

import config from '@/Constants';
import { ProfileProps } from './interfaces';
import {
  Container,
  ProfileContainer,
  ButtonAnimation,
  Button,
  Photo,
  Description,
  Title,
  Subtitle,
  SubtitleContainer,
  Img,
} from './styles';

const ProfileCard: React.FC<ProfileProps> = ({ card }) => {
  const company = card.fields.find((field) => field.type === 'company');
  const img = card.logo?.url ? `${config.BASE_URL}${card.logo?.url}` : null;

  const onOpenLink = () => {
    if (!company || !company.url) return;
    window.open(company.url, '_blank');
  };

  return (
    <Container>
      <ProfileContainer>
        {img ? <Photo src={img} /> : null}
        <Description>
          <Title>{card.name}</Title>
          <SubtitleContainer>
            <Subtitle>{card.position}</Subtitle>
            <Subtitle onClick={() => onOpenLink()}>{company?.value}</Subtitle>
          </SubtitleContainer>
        </Description>
      </ProfileContainer>
      {card.phone && (
        <ButtonAnimation>
          <Button href={`tel:${card.phone}`}>
            <Img src={Phone} />
            {formatPhone(card.phone)}
          </Button>
        </ButtonAnimation>
      )}
    </Container>
  );
};

export default ProfileCard;
