import { createGlobalStyle } from 'styled-components';

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  p {
    margin: 0;
  }

  :root {
    body > #root {
      height: 100vh;
      display: flex;
      background: #1a1a1a;
      -webkit-font-smoothing: antialiased;
      font-family: 'Barlow', sans-serif;
      line-height: 1.6;
    }
  }


  @keyframes show-up {
    from {
      transform: translatey(0.7em);
      opacity: 0;
    }
    to {
      transform: translatey(0em);
      opacity: 1;
    }
  }
`;

export default Global;
