import React from 'react';

import LogoImg from '@assets/logo.png';
import { Container, Logo, ContactUs } from './styles';

const NoCard: React.FC = () => {
  return (
    <Container>
      <Logo src={LogoImg} />
      <ContactUs>Faça o seu cartão</ContactUs>
    </Container>
  );
};

export default NoCard;
