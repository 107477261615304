const getMatch = (cleaned: string) => {
  switch (cleaned.length) {
    case 8:
      return cleaned.match(/^(\d{4})(\d{4})$/);
    case 9:
      return cleaned.match(/^(\d{5})(\d{4})$/);
    case 10:
      return cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
    case 11:
      return cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
    default:
      return null;
  }
};

const formatPhone = (phone: number): string | null => {
  const cleaned = `${phone}`.replace(/\D/g, '');
  const match = getMatch(cleaned);

  if (match && cleaned.length > 9) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  if (match) {
    return `${match[1]}-${match[2]}`;
  }

  return null;
};

export default formatPhone;
