import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Loading from '@pages/Loading';
import NoCard from '@components/NoCard';
import { cardsApiServices } from '@/modules/shared/adapters/ApiServices';
import { Card as CardProps } from '@/modules/shared/domain/interfaces';
import MainCard from '@components/MainCard';
import { Container } from './styles';

interface CardParams {
  id?: string;
}

const Card: React.FC = () => {
  const { id } = useParams<CardParams>();
  const [card, setCard] = useState<CardProps>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCard = async () => {
      if (!id) return;
      setLoading(true);
      try {
        const resp = await cardsApiServices.get({ key: id });
        setCard(resp.body);
      } catch {
        // TO-DO
      }
      setLoading(false);
    };

    fetchCard();
  }, [id]);

  if (loading) return <Loading />;
  if (!id || !card) return <NoCard />;

  return (
    <Container>
      <MainCard card={card} />
    </Container>
  );
};

export default Card;
