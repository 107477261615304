import React from 'react';
import FileSaver from 'file-saver';
import VCard from 'vcard-creator';

import Plus from '@assets/plus.svg';

import { Card } from '@/modules/shared/domain/interfaces';
import { Container, Button, Img } from './styles';

interface FooterProps {
  card: Card;
}

const Footer: React.FC<FooterProps> = ({ card }) => {
  const createFile = () => {
    const myVCard = new VCard();
    myVCard
      .addName(card.name)
      .addCompany(
        card.fields.find((field) => field.type === 'company')?.value || '',
      )
      .addJobtitle(card.position || '')
      .addEmail(
        card.fields.find((field) => field.type === 'email')?.value || '',
      )
      .addURL(card.fields.find((field) => field.type === 'company')?.url || '');
    if (card.phone) myVCard.addPhoneNumber(card.phone, 'WORK');
    const file = new Blob([myVCard.toString()], {
      type: 'text/vcard;charset=utf-8',
    });
    FileSaver.saveAs(file, `${card.name}.vcf`, true);
  };

  return (
    <Container>
      <Button onClick={() => createFile()}>
        <Img src={Plus} />
        Adicionar contato
      </Button>
    </Container>
  );
};

export default Footer;
