import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 2vh;

  display: flex;
  flex-direction: column;

  @media (min-width: 500px) {
    width: auto;
    height: fit-content;

    padding: 4vh 2.5vh;

    box-shadow: -8px -8px 25px rgba(255, 255, 255, 0.05),
      8px 8px 20px rgba(0, 0, 0, 0.6),
      inset -3px -3px 40px rgba(255, 255, 255, 0.05),
      inset 3px 3px 40px rgba(0, 0, 0, 0.4);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 0.6em;

    opacity: 0;
    animation: show-up 500ms 250ms ease forwards;
  }
`;

export const ProfileContainer = styled.div`
  width: 100%;
  min-width: 12vw;
  display: flex;

  min-height: 12vh;

  background-color: #1a1a1a;
  box-shadow: -8px -8px 25px rgba(255, 255, 255, 0.05),
    8px 8px 20px rgba(0, 0, 0, 0.6),
    inset -3px -3px 40px rgba(255, 255, 255, 0.05),
    inset 3px 3px 40px rgba(0, 0, 0, 0.4);

  border-radius: 0.6em;

  opacity: 0;
  animation: show-up 500ms 250ms ease forwards;

  @media (min-width: 500px) {
    box-shadow: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Photo = styled.img`
  height: 19vh;
  width: 15vh;

  object-fit: cover;

  border-radius: 0.6em 0em 0em 0.6em;

  @media (min-width: 500px) {
    border-radius: 0.6em;
  }
`;

export const Description = styled.div`
  padding: 1em;

  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.h1`
  font-size: 3vh;
  color: #e9ecef;

  opacity: 0;
  animation: show-up 500ms 500ms ease forwards;

  @media (min-width: 500px) {
    text-align: center;
  }
`;

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Subtitle = styled.h2`
  font-weight: 300;
  font-size: 1.5vh;
  color: #e9ecef;
  opacity: 0.8;

  opacity: 0;
  animation: show-up 500ms 750ms ease forwards;

  @media (min-width: 500px) {
    text-align: center;
  }
`;

export const ButtonAnimation = styled.div`
  display: flex;

  opacity: 0;
  animation: show-up 500ms 1000ms ease forwards;
`;

export const Button = styled.a`
  text-decoration: none;
  outline: none;
  cursor: pointer;

  flex: 1;
  padding: 0.4em;
  margin-top: 3vh;

  font-family: 'Barlow', sans-serif;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 0.5em;
  background-color: #1a1a1a;
  box-shadow: -8px -8px 25px rgba(255, 255, 255, 0.05),
    8px 8px 20px rgba(0, 0, 0, 0.6),
    inset -3px -3px 40px rgba(255, 255, 255, 0.05),
    inset 3px 3px 40px rgba(0, 0, 0, 0.4);

  transition: transform 300ms ease-in-out;
  :hover,
  :focus {
    transform: scale(1);
  }

  :active {
    transform: scale(0.9);
  }

  @media (hover) {
    transform: scale(0.9);
  }

  border: none;
  padding: 0.6em 0.4em;

  font-weight: 300;
  color: #f55d0b;
  font-size: 1.35em;

  /* white-space: nowrap; */
`;

export const Img = styled.img`
  height: 1.28em;
  width: 1.28em;

  margin-right: 0.7em;

  @media (min-width: 500px) {
    height: 3.5vh;
    width: 3.5vh;
  }
`;
