import Github from '@assets/github.svg';
import Facebook from '@assets/facebook.svg';
import Instagram from '@assets/instagram.svg';
import Twitter from '@assets/twitter.svg';
import Linkedin from '@assets/linkedin.svg';
import Youtube from '@assets/youtube.svg';
import Pinterest from '@assets/pinterest.svg';
import Tiktok from '@assets/tiktok.svg';
import Behance from '@assets/behance.svg';
import Globe from '@assets/globe.svg';
import Email from '@assets/mail.svg';

import config from '@/Constants';
import { Card } from '@/modules/shared/domain/interfaces';
import { Contact } from '@/components/LinkCard/interfaces';

const formatFields = (card: Card): Contact[] => {
  const fieldsComponents = card.fields.map((field) => {
    const img = field.img?.url ? `${config.BASE_URL}${field.img?.url}` : null;
    const baseConfig = {
      id: field.id,
      title: field.value,
      url: field.url,
    };

    switch (field.type) {
      case 'instagram':
        return { ...baseConfig, imgUrl: img || Instagram };
      case 'linkedin':
        return { ...baseConfig, imgUrl: img || Linkedin };
      case 'twitter':
        return { ...baseConfig, imgUrl: img || Twitter };
      case 'youtube':
        return { ...baseConfig, imgUrl: img || Youtube };
      case 'github':
        return { ...baseConfig, imgUrl: img || Github };
      case 'facebook':
        return { ...baseConfig, imgUrl: img || Facebook };
      case 'pinterest':
        return { ...baseConfig, imgUrl: img || Pinterest };
      case 'tiktok':
        return { ...baseConfig, imgUrl: img || Tiktok };
      case 'behance':
        return { ...baseConfig, imgUrl: img || Behance };
      case 'email':
        return {
          ...baseConfig,
          imgUrl: img || Email,
          url: `mailto:${field.value}`,
        };
      default:
        return { ...baseConfig, imgUrl: img || Globe };
    }
  });

  return fieldsComponents;
};

export { formatFields };
