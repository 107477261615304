import styled from 'styled-components';

type IndicatorProps = {
  mode: string;
};

type BackgroundProps = {
  mode: string;
  variant: string;
};

export const AppContainer = styled.div`
  width: 100%;
  height: 100vh;
  color: #c1c3c5;
  background-color: #090a0b;
  position: absolute;
  overflow: hidden;
`;

export const Header = styled.div`
  background-color: #101214;
  height: 18%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
`;

export const HeaderTitles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  padding-top: 30px;
`;

export const NavMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 40px;
`;

export const NavItem = styled.span`
  width: 50%;
  height: 100%;
  font-family: 'Space Mono';
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  cursor: pointer;
`;

export const InfoButton = styled.p`
  font-family: 'inter';
  font-weight: medium;
  width: fit-content;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

export const Logo = styled.img`
  width: 169px;
`;

export const Indicator = styled.div<IndicatorProps>`
  width: 50%;
  height: 2px;
  background-color: #f55d0b;
  position: absolute;
  bottom: 0;
  left: ${({ mode }) => (mode === 'iphone' ? '0' : '50%')};

  transition: 0.5s;
`;

export const MainContainer = styled.div`
  width: 100%;
  height: 70%;
  background: linear-gradient(
    180deg,
    rgba(9, 10, 11, 0.95) 55%,
    rgba(9, 10, 11, 0) 100%
  );
  z-index: 5;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.div`
  width: 100%;
  height: 12%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
`;

export const TitleText = styled.h1`
  font-family: 'Space Mono';
  font-weight: bold;
  font-size: 16px;
  margin: 0;

  @media (min-width: 1500px) {
    font-size: 20px;
  }
`;

export const InstructionsContainer = styled.div`
  margin: 24px 20px;
  justify-content: center;

  @media (min-width: 1500px) {
    align-self: flex-end;
    margin-right: 250px;
    margin-top: 150px;
  }
`;

export const Instruction = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: flex-start;
  font-family: 'inter';
  font-size: 14px;

  @media (min-width: 1500px) {
    margin-bottom: 40px;
    font-size: 18px;
  }
`;

export const InstructionNumber = styled.p`
  margin: 0;
  margin-right: 15px;
  font-weight: 500;
`;

export const InstructionText = styled.p`
  margin: 0;
  font-weight: 300;
`;

export const BackgroundImage = styled.img<BackgroundProps>`
  position: absolute;
  width: 560px;
  transform: ${({ mode }) =>
    mode === 'iphone' ? 'translate(-35%, -65%)' : 'translate(-5%, -65%)'};
  opacity: ${({ mode, variant }) => (mode === variant ? '1' : '0')};

  transition: 0.5s;
`;

export const IphoneAdvice = styled.p`
  color: #717374;
  text-align: center;
  font-size: 14px;
`;

export const NFCSymbol = styled.img`
  width: 14px;
  margin: 0 4px;
  vertical-align: middle;
`;

export const BackgroundFade = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 6;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div`
  z-index: 7;
  background-color: #222426;
  width: calc(40% + 150px);
  height: 55vh;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 56px 24px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const ModalText = styled.h2`
  font-family: 'inter';
  font-weight: 300;
  font-size: 16px;
  color: white;
  text-align: center;
  margin: 0 24px;
`;

export const ModalImage = styled.img`
  width: 250px;
  margin-top: 30px;
`;

export const CloseButtom = styled.span`
  position: absolute;
  top: 3%;
  right: 5%;
  font-family: 'inter';
  font-weight: 500;
  cursor: pointer;
  color: #828282;
`;
