import axios, { AxiosInstance, AxiosResponse } from 'axios';

import {
  HttpParamsConfig,
  HttpResponse,
  IApiHelper,
} from '@/modules/shared/data/protocols/http';
import constants from '@/Constants';

export type getParams = {
  url: string;
};

export type postParams = {
  url: string;
  body?: any;
};

const apiConfig = {
  baseUrl: constants.BASE_URL,
  validateStatus: () => {
    return true;
  },
};

class AxiosHelper implements IApiHelper {
  token: string | null;

  baseUrl: string;

  public instance: AxiosInstance;

  constructor(con: typeof apiConfig) {
    this.baseUrl = con.baseUrl;
    this.token = null;
    this.instance = axios.create();
  }

  public getFullPath(): string {
    return this.baseUrl;
  }

  private prepareConfig(): HttpParamsConfig {
    const header = this.token ? { Authorization: this.token } : undefined;
    const config = {
      headers: header,
      timeout: 50000,
      validateStatus: () => {
        return true;
      },
    };
    return config;
  }

  public setToken(token: string): void {
    this.token = token;
  }

  public clearToken(): void {
    this.token = null;
  }

  public createInterceptor(
    onResponse?: (response: AxiosResponse<any>) => AxiosResponse<any>,
    onReject?: (error: any) => any,
  ): number {
    const id = this.instance.interceptors.response.use(onResponse, onReject);
    return id;
  }

  public removeInterceptor(id: number) {
    this.instance.interceptors.response.eject(id);
  }

  public async get<T = any>(params: getParams): Promise<HttpResponse<T>> {
    const url = params.url.includes('http')
      ? params.url
      : `${this.baseUrl}/${params.url}`;
    const response = await this.instance.get(url, this.prepareConfig());

    return {
      statusCode: response.status,
      body: response.data,
    };
  }

  public async post<T = any>(
    params: postParams,
  ): Promise<HttpResponse<T | undefined>> {
    const url = params.url.includes('http')
      ? params.url
      : `${this.baseUrl}/${params.url}`;
    try {
      const response = await this.instance.post(
        url,
        params.body,
        this.prepareConfig(),
      );

      return {
        statusCode: response.status,
        body: response.data,
      };
    } catch (err) {
      return {
        statusCode: 404,
        body: undefined,
      };
    }
  }

  public async patch(params: postParams): Promise<HttpResponse<any>> {
    const url = params.url.includes('http')
      ? params.url
      : `${this.baseUrl}/${params.url}`;
    const response = await this.instance.patch(
      url,
      params.body,
      this.prepareConfig(),
    );

    return {
      statusCode: response.status,
      body: response.data,
    };
  }

  public async delete(params: postParams): Promise<HttpResponse<any>> {
    const url = params.url.includes('http')
      ? params.url
      : `${this.baseUrl}/${params.url}`;
    const response = await this.instance.delete(url, {
      data: params.body,
      ...this.prepareConfig(),
    });

    return {
      statusCode: response.status,
      body: response.data,
    };
  }
}

const api = new AxiosHelper(apiConfig);

export default api;
