import React from 'react';

import LogoPng from '@assets/logo.png';
import {
  Container,
  HeaderTitle,
  RightContainer,
  Logo,
  HeaderButton,
} from './styles';

const Header: React.FC = () => {
  return (
    <Container>
      <RightContainer>
        <Logo src={LogoPng} />
        <HeaderTitle>Dynamic Techs</HeaderTitle>
      </RightContainer>
      <HeaderButton>Faça o seu cartão</HeaderButton>
    </Container>
  );
};

export default Header;
