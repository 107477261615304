import styled from 'styled-components';
import { BaseButton } from '@/components/BaseButton/styles';

export const Container = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const Logo = styled.img`
  width: 20vh;
  height: 20vh;

  filter: invert(1);
`;

export const ContactUs = styled(BaseButton)`
  margin-top: 3em;

  padding: 0.6em 1.8em;
  border: 0;
  box-shadow: -8px -8px 25px rgba(255, 255, 255, 0.05),
    8px 8px 20px rgba(0, 0, 0, 0.6),
    inset -3px -3px 40px rgba(255, 255, 255, 0.05),
    inset 3px 3px 40px rgba(0, 0, 0, 0.4);
  background-color: #1a1a1a;

  font-weight: 600;
  color: #e9ecef;
  font-size: 2vh;
`;
